
import { useStore } from "vuex";
import { computed, onUpdated } from "vue";

export default {
  name: "OfferTabs",
  emits: ["changeTab"],

  setup(props, { emit }) {
    const store = useStore();
    const saleJobId = computed(() => store.getters.ForwarderOfferId);
    const saleJob = computed(() => store.getters.ForwarderOfferItem);
    const saleJobTab = computed(() => store.getters.ForwarderTabItem);

    const handleTabClick = (tab) => {
      emit("changeTab", tab);
    };

    onUpdated(() => {
      if ("tab" in saleJobTab.value) {
        const overview = document.getElementById("overview") as any;
        const tab = document.getElementById(saleJobTab.value.tab) as any;
        tab.classList.add("active");
        overview.classList.remove("active");
        emit("changeTab", saleJobTab.value.tab);
        store.commit("removeForwarderTab");
      }
    });

    return {
      props,
      saleJobId,
      saleJob,
      handleTabClick,
    };
  },
};
